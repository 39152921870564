<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">certificaciones mantenimiento</span>
    </template>
    <MaintenanceCertificationsTable />
  </BaseCard>
</template>

<script>
import MaintenanceCertificationsTable from '@/components/maintenance-certifications/table/MaintenanceCertificationsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { 
    BaseCard, 
    MaintenanceCertificationsTable
  },
}
</script>